.slideshowLocation {
	width: 50%;
	border-right: 1px solid $light-grey-border-light-background;
	border-left: 1px solid $light-grey-border-light-background;
	
	.slideshow {
		width: 100%;

		&__slide {
			display: flex;
			align-items: center;
			justify-content: center;

			div {
				position: relative;
				width: 35%;
				padding-top: 35%;
				margin: 0 auto;
			}

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
			}

			&.active {
				transform: translate3d(0, 0, 0);
	
				&.isTransitionning-previous {
					transform: translate3d(100%, 0, 0);
				}
	
				&.isTransitionning-next {
					transform: translate3d(-100%, 0, 0);
				}

				&::after {
					content: none;
				}

				&[class*="isTransitionning"] {
					transition: transform 600ms cubic-bezier(0.475, 0.045, 0.015, 1.010) .1s;
				}
			}
	
			&.isSetting-previous {
				transform: translate3d(-100%, 0, 0);
			}
	
			&.isSetting-next {
				transform: translate3d(100%, 0, 0);
			}
	
			&.isTransitionning {
				transition: transform 600ms cubic-bezier(0.475, 0.045, 0.015, 1.010) .1s;
				transform: translate3d(0, 0, 0);
			}
		}
	}
	
	&__image {
		padding-top: 50.077%;
	}
	
	&__wrapper {
		display: flex;
		flex-direction: column;
		width: 25%;
	}
	
	&__name {
		position: relative;
		padding-top: 50%;
		width: 100%;
	
		li {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 30px;
			
			p {
				text-transform: uppercase;
				font-family: "Lato-Bold";
			}

			&.active {
				transform: translate3d(0, 0, 0);
	
				&.isTransitionning-previous {
					transform: translate3d(0, 100%, 0);
				}
	
				&.isTransitionning-next {
					transform: translate3d(0, -100%, 0);
				}

				&::after {
					content: none;
				}

				&[class*="isTransitionning"] {
					transition: transform 450ms cubic-bezier(0.475, 0.045, 0.015, 1.010) .2s;
				}
			}
	
			&.isSetting-previous {
				transform: translate3d(0, -100%, 0);
			}
	
			&.isSetting-next {
				transform: translate3d(0, 100%, 0);
			}
	
			&.isTransitionning {
				transition: transform 450ms cubic-bezier(0.475, 0.045, 0.015, 1.010) .2s;
				transform: translate3d(0, 0, 0);
			}
		}
	}
	
	&__button-wrapper {
		display: flex;
		width: 100%;
		border-top: 1px solid $light-grey-border-light-background;
	}
	
	&__button {
		position: relative;
		width: 50%;
		padding-top: 50%;
	
		&:first-child {
			border-right: 1px solid $light-grey-border-light-background;
			border-left: 1px solid $light-grey-border-light-background;
	
			&::before {
				transform: translate3d(-50%, calc(-50% + 9px), 0) rotate(45deg);
			}
	
			&::after {
				transform: translate3d(-50%, calc(-50% - 8px), 0) rotate(-45deg);
			}
		}
	
		&:last-child {
			&::before {
				transform: translate3d(-50%, calc(-50% + 9px), 0) rotate(-45deg);
			}
	
			&::after {
				transform: translate3d(-50%, calc(-50% - 8px), 0) rotate(45deg);
			}
		}
	
		&::before,
		&::after {
			content: '';
			position: absolute;
			display: block;
			width: 25px;
			height: 2px;
			top: 50%;
			left: 50%;
			background: $main-black;
			pointer-events: none;
		}
	}
}

@media (max-width: 960px) {
	.slideshowLocation {
		width: 100%;
		.slideshow {
			width: 100%;

			.slideshowLocation__image {
				position: relative;
			}
		}

		&__wrapper {
			width: 100%;

			.slideshow {
				order: 2;

				.slideshowLocation__name {
					position: relative;
					border-top: 1px solid $light-grey-border-light-background;
					border-bottom: 1px solid $light-grey-border-light-background;
				}
			}
		}

		&__button-wrapper {
			order: 1;
		}
	}
}