.materielHome {
	background-color: $main-black;
	color: #ffffff;

	.wrapper {
		border-right: 1px solid $light-grey-border-dark-background;
		border-left: 1px solid $light-grey-border-dark-background;
	}

	&__grid {
		display: grid;
		height: 100%;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, auto);
		grid-template-areas:
			"a a e"
			"d c b";
	}

	.a,
	.c,
	.d {
		border-right: 1px solid $light-grey-border-dark-background;
	}

	.a,
	.b {
		border-bottom: 1px solid $light-grey-border-dark-background;
	}

	.a,
	.b {
		display: flex;
		align-items: center;
		padding: 50px 30px 30px;
	}

	.c {
		display: flex;
		justify-content: center;
		align-items: center;

		.materielHome__text {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			font-size: 80px;
			margin-bottom: 20px;

			span {
				font-family: 'Lato-Light';
				font-size: 18px;
			}
		}
	}


	.d {
		position: relative;
		padding-top: 75%;
		overflow: hidden;

		ul {
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__detail {
		position: absolute;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-direction: column;
		height: 100%;
		top: 0;
		left: 0;
		padding: 30px 40px 30px 30px;
		
		&-title,
		&-text {
			position: relative;
			z-index: 2;
		}

		&-title {
			font-size: 18px;
		}
	}

	.e {
		display: flex;
		align-items: flex-end;
		padding: 100px 0 0;
	}


	&__link {
		background-color: #ffffff;
		color: $main-black;
	}
}


@media (max-width: 960px) {
	.materielHome {
		&__grid {
			grid-template-columns: 1fr;
			grid-template-rows: repeat(2, auto);
			grid-template-areas:
				"a"
				"d"
				"c"
				"b"
				"e";
		}

		.a {
			padding: 60px 30px 30px;
		}

		.b {
			padding: 30px;
		}

		.c {
			justify-content: flex-start;
			padding: 30px;
			border-bottom: 1px solid $light-grey-border-dark-background;
		}

		.d {
			padding-top: 100%;
			justify-content: flex-start;
			border-bottom: 1px solid $light-grey-border-dark-background;

			.materielHome__text {
				text-align: justify;
				font-size: 20px;
			}
		}

		.e {
			padding: 0 0 30px;
		}
	}
}