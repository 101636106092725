.presentationHome {
	background-color: $main-black;
	color: #ffffff;

	&__grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: 10.4166667vw max-content max-content max-content;
		;
		grid-template-areas:
			"a a b b"
			"c c c ."
			"d d d d"
			"e e e e";
	}

	.wrapper {
		border-right: 1px solid $light-grey-border-dark-background;
		border-left: 1px solid $light-grey-border-dark-background;
	}

	.a,
	.c,
	.d,
	.f,
	.h {
		border-right: 1px solid $light-grey-border-dark-background;
	}

	.a,
	.b,
	.e,
	.f,
	.g {
		border-bottom: 1px solid $light-grey-border-dark-background;
	}

	.e,
	.f,
	.g,
	.h {
		position: relative;
		padding-top: 100%;
		overflow: hidden;

		&::after {
			content: '';
			position: absolute;
			background-color: $main-black;
			opacity: 0.2;

		}

		&:hover {
			.presentationHome__detail {
				opacity: 0.8;

				&-title {
					opacity: 1;
					transform: translateY(0);
					transition: opacity .2s linear .1s, transform .3s cubic-bezier(0.130, 0.765, 0.145, 0.965) .1s;
				}

				&-text {
					opacity: 1;
					transition: opacity .2s linear .15s;
				}
			}
		}
	}

	.a {
		padding: 80px 60px 30px 30px;
	}

	.c {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 30px;

		br {
			content: "";
			display: block;
			font-size: 20px;
			margin: 10px;
		}
	}

	&__title {
		margin-bottom: 20px;
	}

	&__detail {
		&-title {
			display: block;
			font-family: 'Lato-Bold';
			margin-bottom: 10px;
			text-transform: uppercase;
		}
	}
}

@media (max-width: 960px) {
	.presentationHome {
		&__grid {
			display: block;
		}

		.a,
		.b,
		.d,
		.e,
		.f,
		.g,
		.h{
			border-right: none;
			border-left: none;
			border-bottom: none;
		}

		.a {
			padding: 60px 60px 30px 30px;
		}

		.c {
			padding: 30px 30px 60px;
			border-top: 1px solid $light-grey-border-dark-background;
			margin-bottom: 20px;
			border-right: none;
			border-left: none;
		}

		.d {
			display: block;
		}

		&__title {
			margin-bottom: 0;
		}

		&__detail {
			position: relative;
			opacity: 1;
			background-color: $main-black;

			&-title {
				font-size: 23px;
				opacity: 1;
				transform: translateY(0);
				margin-bottom: 10px;
			}

			&-text {
				opacity: 1;
			}
		}

		.cover {
			width: calc(100% - 30px);
			left: 15px;
		}
	}
}