.slideshowPages {
	border-top: 1px solid $light-grey-border-light-background;

	br {
		content: "";
		display: block;
		font-size: 20px;
		margin: 10px;
	}

	&__grid {
		display: grid;
		width: 100%;
		grid-template-columns: 50% 50%;
		grid-template-areas:
			"a b";

		&.reverse {
			grid-template-areas:
				"b a";

			.a {
				border-left: 1px solid $light-grey-border-light-background;
				border-right: none;
			}

			.slideshowPages {
				&__bottom-part {
					flex-direction: row-reverse;
					padding-right: 30px;
					padding-left: 0;
				}


				&__button {
					position: relative;
					width: 50%;
					padding-top: 50%;

					&:first-child {
						border-right: 1px solid $light-grey-border-light-background;
						border-left: none;
					}

					&:last-child {
						border-right: 1px solid $light-grey-border-light-background;
					}
				}
			}
		}
	}

	.a {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		padding: 30px 0 0;
		border-right: 1px solid $light-grey-border-light-background;
	}

	.b {
		padding-top: 63%;
		position: relative;
	}

	&__slideshow {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	&__description {
		padding: 0 30px 30px;
		overflow-y: scroll;
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
		
		&::-webkit-scrollbar { 
			display: none;  /* Safari and Chrome */
		}
	}

	&__bottom-part {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding-left: 30px;
		border-top: 1px solid $light-grey-border-light-background;

		span {
			display: block;
			text-transform: uppercase;
			font-family: 'Lato-Bold';
			margin-bottom: 30px;
		}
	}

	&__button-wrapper {
		display: flex;
		width: 40%;
	}

	&__button {
		position: relative;
		width: 50%;
		padding-top: 50%;

		&:first-child {
			border-right: 1px solid $light-grey-border-light-background;
			border-left: 1px solid $light-grey-border-light-background;

			&::before {
				transform: translate3d(-50%, calc(-50% + 9px), 0) rotate(45deg);
			}

			&::after {
				transform: translate3d(-50%, calc(-50% - 8px), 0) rotate(-45deg);
			}
		}

		&:last-child {
			&::before {
				transform: translate3d(-50%, calc(-50% + 9px), 0) rotate(-45deg);
			}

			&::after {
				transform: translate3d(-50%, calc(-50% - 8px), 0) rotate(45deg);
			}
		}

		&::before,
		&::after {
			content: '';
			position: absolute;
			display: block;
			width: 25px;
			height: 2px;
			top: 50%;
			left: 50%;
			background: $main-black;
			pointer-events: none;
		}
	}

	h2 {
		margin-bottom: 20px;
	}
}

@media (max-width: 960px) {
	.slideshowPages {
		&__grid {
			display: block;

			&.reverse {
				.a {
					border-left: 1px solid $light-grey-border-light-background;
					border-right: none;
				}
	
				.slideshowPages {
					&__bottom-part {
						flex-direction: row;
						padding-right: 0;
						padding-left: 30px;
					}
	
	
					&__button {
	
						&:first-child {
							border-left: 1px solid $light-grey-border-light-background !important;
						}
	
						&:last-child {
							border-right: none;
						}
					}

					&__slideshow {
						border-top: 1px solid $light-grey-border-light-background;
					}
				}
			}
		}

		&__button {
			&:first-child {
				border-left: 1px solid $light-grey-border-light-background !important;
			}
		}
	}
}