.headerPages {
	height: 70vh;

	&__grid {
		display: grid;
		height: 100%;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-template-areas:
			"a b b c"
			"d e e f";
	}

	.a,
	.b,
	.d,
	.e {
		border-right: 1px solid $light-grey-border-light-background;
	}

	.a,
	.b,
	.c {
		border-bottom: 1px solid $light-grey-border-light-background;
	}

	.a,
	.c {
		align-items: flex-end;
		display: flex;
	}

	.b {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.e  {
		padding: 0 30px;
	}

	h1 {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	.squareButton {
		background-color: $main-black;
		color: #fff;

		&:visited {
			color: #fff;
		}
	}
}

@media (max-width: 960px) {
	.headerPages {
		&__grid {
			display: grid;
			height: 100%;
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: 120px auto;
			grid-template-areas:
				"b c c c"
				"e e e e";
		}

		h1 {
			font-size: 45px;
		}

		.b {
			border-right: 1px solid rgba(31,31,31,.1) !important;
		}

		.a,
		.d,
		.f,
		.g {
			display: none;
		}

		.b,
		.e,
		.b a {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 100%;
		}

		.logo {
			transform: scale(0.5);
		}
	}
}