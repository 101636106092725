.dropDownLocation {
	border-top: 1px solid $light-grey-border-light-background;

	&__topSection {
		display: flex;

		padding-left: 12.5%;
	}

	&__title {
		display: flex;
		align-items: center;
		font-size: 32px;
		width: 85.6%;
		padding-left: 30px;
		border-right: 1px solid $light-grey-border-light-background;
		border-left: 1px solid $light-grey-border-light-background;
	}

	&__toggleButton {
		position: relative;
		width: 14.4%;
		padding-top: 14.4%;
		background: $main-black;


		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			background: #fff;
			width: 40px;
			height: 2px;
			pointer-events: none;
		}

		&::before {
			transform: translate3d(-50%, -50%, 0);
		}

		&::after {
			transition: transform .3s cubic-bezier(0.85, 0, 0.15, 1);
			transform: translate3d(-50%, -50%, 0) rotate(90deg);
		}

		&.active {
			&::after {
				transform: translate3d(-50%, -50%, 0) rotate(180deg);
			}
		}
	}

	&__bottomSection {
		display: flex;
		height: auto;
		overflow: hidden;

		&.setHeight {
			height: var(--height);
			transition: height .6s cubic-bezier(0.85, 0, 0.15, 1);
			border-top: 1px solid $light-grey-border-light-background;

			&.inactive {
				height: 0;
				border-top: none;
			}
		}

		.slideshowLocation {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				background: $main-black;
				width: 100%;
				height: 100%;
				pointer-events: none;
				transform: translateY(0);
				transition: transform .4s cubic-bezier(0.22, 1, 0.36, 1);
				top: 0;
				left: 0;
			}

			&.active {
				&::after {
					transform: translateY(101%);
					transition: transform .4s cubic-bezier(0.55, 0, 1, 0.45) .25s;
				}
			}
		}
	}

	&__description {
		position: relative;
		display: flex;
		width: 25%;
		align-items: center;
		background: $main-black;
		color: #fff;
		padding: 0 20px;
		font-size: 20px;
		overflow: hidden;

		&::after {
			content: '';
			display: block;
			position: absolute;
			height: 100%;
			width: 100%;
			background: #fff;
			transform: translateX(0);
			transition: transform .4s cubic-bezier(0.22, 1, 0.36, 1);
			top: 0;
			left: 0;
			z-index: 1;
		}

		&.active {
			&::after {
				transform: translateX(101%);
				transition: transform .35s cubic-bezier(0.55, 0, 1, 0.45) .35s;
			}
		}
	}
}

@media (max-width: 960px) {
	.dropDownLocation {
		&__topSection {
			padding-left: 0;
			padding: 30px 0;
		}
	
		&__title {
			font-size: 20px;
			border-left: none;
		}

		&__bottomSection {
			flex-direction: column;
		}

		&__description {
			width: 100%;
			padding: 30px;
			order: 1;
			font-size: 14px;

			&::after {
				transition: transform .4s ease-in-out;
			}
	
			&.active {
				&::after {
					transform: translateX(101%);
					transition: transform .35s ease-in-out .35s;
				}
			}
		}

		&__bottomSection {
			.slideshowLocation {
				order: 3;
	
				&::after {
					transition: transform .4s ease-in-out;
				}
	
				&.active {
					&::after {
						transition: transform .4s ease-in-out .35s;
					}
				}
			}

			.slideshowLocation__wrapper {
				order: 2;
			}
		}
	}
}