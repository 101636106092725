.headerHome {
	position: relative;
	height: 90vh;
	background-image: url(../assets/images/home/accueil.jpg);
	background-position: 50%;
	background-size: cover;
	&::after {
		content: '';
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(37, 37, 37, 0.2) 9%, rgba(0, 0, 0, 0.6) 165%);
	}

	&__grid {
		display: grid;
		height: 100%;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(3, 1fr);
		grid-template-areas:
			"a b b c"
			"d d d e"
			"h g g f";
	}

	.wrapper {
		position: relative;
		z-index: 2;
		border-right: 1px solid $light-grey-border-dark-background;
		border-left: 1px solid $light-grey-border-dark-background;
	}

	.a,
	.b,
	.c,
	.d,
	.e {
		border-bottom: 1px solid $light-grey-border-dark-background;
	}

	.a,
	.b,
	.d,
	.g,
	.h {
		border-right: 1px solid $light-grey-border-dark-background;
	}

	.f {
		display: flex;
		align-items: flex-end;
	}

	.b {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 50px 50px 30px;

		h1 {
			margin-bottom: 20px;
		}
	}

	&__contact-link {
		color: $main-black;
		background-color: #fff;
		cursor: pointer;
	}

	&__text {
		font-size: 16px;
		font-weight: 400;
		color: #ffffff;
		text-shadow: 0 0 2px rgba(0,0,0,0.51);
	}
}

@media (max-width: 960px) {
	.headerHome {
		height: 100vh;
	
		&__grid {
			display: grid;
			height: 100%;
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: 1fr 1fr 1fr 1fr 1fr auto;
			grid-template-areas:
				"b b b b"
				"a a a a"
				"d d d d"
				"d d d d"
				"d d d d"
				"f f f f";
		}
		
		h1 {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 100%;
		}

		.logo {
			transform: scale(0.5);
		}

		&__text {
			padding: 20px 0;
			font-size: 25px;
		}

		.b {
			padding: 50px 30px 30px;
		}

		.a,
		.f {
			border-right: none;
		}

		.g,
		.h {
			border-bottom: none;
		}
	}
}