.footer {
	&.whiteVersion {
		background-color: #fff;
		color: $main-black;
		border-top: 1px solid $light-grey-border-light-background;


		.wrapper {
			border-right: 1px solid $light-grey-border-light-background;
			border-left: 1px solid $light-grey-border-light-background;
		}

		.a,
		.c,
		.d,
		.e,
		.f {
			border-right: 1px solid $light-grey-border-light-background;
		}

		.a,
		.b,
		.d,
		.e {
			border-bottom: 1px solid $light-grey-border-light-background;
		}

		.g {
			border-top: 1px solid $light-grey-border-light-background;
		}

		.squareButton {
			background: $main-black;
			color: #fff;
		}
	}

	background-color: $main-black;
	color: #fff;
	border-top: 1px solid $light-grey-border-dark-background;

	.wrapper {
		border-right: 1px solid $light-grey-border-dark-background;
		border-left: 1px solid $light-grey-border-dark-background;
	}

	&__grid {
		display: grid;
		height: 100%;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(4, auto);
		grid-template-areas:
			"a b b"
			"c d ."
			"c . ."
			"c f e"
			"g g g";

		> div:not(.f) {
			padding: 30px;
		}
	}

	.a,
	.c,
	.d,
	.e,
	.f {
		border-right: 1px solid $light-grey-border-dark-background;
	}

	.a,
	.b,
	.d,
	.f {
		border-bottom: 1px solid $light-grey-border-dark-background;
	}

	.a {
		padding-top: 80px;
	}

	.c {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding-left: 30px;
	}

	.d {
		li:first-child {
			font-family: 'Lato-Bold';
			margin-bottom: 10px;
		}
	}

	.g {
		height: 80px;
		border-top: 1px solid $light-grey-border-dark-background;
		p {
			text-align: right;
		}
	}

	&__adress {
		text-align: left;
	}

	.logo {
		transform: none;
		margin-bottom: 30px;
	}

	.squareButton {
		background: #fff;
		color: $main-black;
	}
}

@media (max-width: 960px) {
	.footer {
		&__grid {
			display: block;

			> div.a:not(.f) {
				padding: 100px 30px 30px;
			}
		}

		.d {
			border-top: 1px solid $light-grey-border-dark-background;
		}

		.g p {
			text-align: left;
		}
	}
}