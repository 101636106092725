.prestationsHome {
	background-color: #fff;
	color: $main-black;

	&__grid {
		display: grid;
		height: 100%;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(7, auto);
		grid-template-areas:
			"a a c d"
			"a a c d"
			"e e f g"
			"e e f g"
			"e e f g"
			"h h h h";
	}

	.a,
	.c,
	.e,
	.f {
		border-right: 1px solid $light-grey-border-light-background;
	}

	.a,
	.c,
	.d {
		border-bottom: 1px solid $light-grey-border-light-background;
	}

	.a {
		display: flex;
		align-items: flex-end;
		padding: 100px 30px 30px;

		h2 {
			width: 250px;
		}
	}

	.e {
		position: relative;
	}

	.h {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: auto;
		grid-template-areas:
			". span-a span-b .";

		span {
			padding: 0 0 35%;
		}
	}

	.span-a {
		grid-area: span-a;
		border-right: 1px solid $light-grey-border-light-background;
	}

	.span-b {
		grid-area: span-b;
		border-right: 1px solid $light-grey-border-light-background;
	}

	&__content {
		width: 75%;
	}

	&__text {
		padding: 30px;
	}

	&__link {
		background-color: $main-black;
		color: #fff;
	}

	&__button-wrapper {
		display: flex;
		width: 66%;
		margin-left: 33%;
	}

	&__button {
		position: relative;
		width: 50%;
		padding-top: 50%;
		border-bottom: 1px solid $light-grey-border-light-background;

		&:first-child {
			border-right: 1px solid $light-grey-border-light-background;
			border-left: 1px solid $light-grey-border-light-background;

			&::before {
				transform: translate3d(-50%, calc(-50% + 9px), 0) rotate(45deg);
			}

			&::after {
				transform: translate3d(-50%, calc(-50% - 8px), 0) rotate(-45deg);
			}
		}

		&:last-child {
			&::before {
				transform: translate3d(-50%, calc(-50% + 9px), 0) rotate(-45deg);
			}

			&::after {
				transform: translate3d(-50%, calc(-50% - 8px), 0) rotate(45deg);
			}
		}

		&::before,
		&::after {
			content: '';
			position: absolute;
			display: block;
			width: 25px;
			height: 2px;
			top: 50%;
			left: 50%;
			background: $main-black;
			pointer-events: none;
		}
	}

	&__slideshow {
		position: absolute;
		width: calc(125% + 3px);
		height: 140%;
		bottom: 0;
		left: 75%;
		overflow: hidden;
		border: 1px solid $light-grey-border-light-background;

		&::before {
			content: '';
			position: absolute;
			display: block;
			width: 1px;
			height: 100%;
			top: 0;
			left: 100%;
			background: $light-grey-border-light-background;
			pointer-events: none;
		}
	}
}

@media (max-width: 960px) {
	.prestationsHome {
		&__grid {
			display: block;
		}

		&__content,
		&__button-wrapper {
			width: 100%;
		}

		&__content {
			display: flex;
			flex-direction: column;
		}

		&__button-wrapper {
			order: 1;
		}

		&__text {
			order: 2;
		}

		&__link {
			order: 3;
		}

		&__button-wrapper {
			margin-left: 0;
		}

		&__slideshow {
			position: relative;
			width: calc(100%);
			padding-bottom: 120%;
			height: auto;
			bottom: unset;
			left: unset;
		}

		.a,
		.e {
			border-right: none;
		}

		.a,
		.c {
			border-bottom: none;
		}

		.a {
			padding: 60px 30px 30px;
		}

		.e {
			display: flex;
			flex-direction: column-reverse;
		}
	}
}