$fontsPath: '../assets/fonts/';

@font-face {
	font-family: 'Lato-Black';
	src: url($fontsPath + 'Lato-Black.ttf');
}

@font-face {
	font-family: 'Lato-Bold';
	src: url($fontsPath + 'Lato-Black.ttf');
}

@font-face {
	font-family: 'Lato-Regular';
	src: url($fontsPath + 'Lato-Regular.ttf');
}

@font-face {
	font-family: 'Lato-Light';
	src: url($fontsPath + 'Lato-Light.ttf');
}

@font-face {
	font-family: 'Metrophobic-Regular';
	src: url($fontsPath + 'Metrophobic-Regular.ttf');
}