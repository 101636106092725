html {
	scroll-behavior: smooth;
}

body {
	font-family: 'Metrophobic-Regular';
}

.wrapper {
	height: 100%;
	max-width: 1334px;
	margin: 0 auto;
	border-right: 1px solid $light-grey-border-light-background;
	border-left: 1px solid $light-grey-border-light-background;
}

.a {
	grid-area: a;
}

.b {
	grid-area: b;
}

.c {
	grid-area: c;
}

.d {
	grid-area: d;
}

.e {
	grid-area: e;
}

.f {
	grid-area: f;
}

.g {
	grid-area: g;
}

.h {
	grid-area: h;
}

.i {
	grid-area: i;
}

.cover {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 0;
	object-fit: cover;
}

.logo {
	path {
		stroke: #fff;
		stroke-width: 2;
		fill: #fff;
		stroke-dashoffset: 0;
		stroke-dasharray: 1000;
	}

	&.black {
		path {
			stroke: $main-black;
			fill: $main-black;
		}
	}
}

[class$="text"] {
	font-family: 'Lato-Light';
	font-size: 16px;
}

* {
	box-sizing: border-box;
}

.squareButton {
	display: block;
	width: 100%;
	padding: 4vh 0;
	text-align: center;
	text-decoration: none;
	font-family: 'Metrophobic-Regular';
	font-size: 16px;
	text-transform: uppercase;
	cursor: pointer;
}

.a11y {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

section {
	border-top: 1px solid $light-grey-border-dark-background;
}

h1 {
	font-size: 4vw;
	font-weight: 400;
}

h2,
.h2 {
	font-size: 55px;
	font-weight: 400;
}

.withMask {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	pointer-events: none;
	background: linear-gradient(0deg, rgba(0,0,0,0.95) 0%, rgba(37,37,37,0.6) 43%, rgba(0,0,0,0.35) 100%);
}

.bottomLink {
	display: none;
}

@media (max-width: 960px) {

	h2,
	.h2 {
		font-size: 30px;
	}

	[class$="text"] {
		font-size: 16px;
	}

	.squareButton {
		padding: 20px 0;
	}

	.bottomLink {
		display: block;
		padding: 40px 0 40px 30px;
		border-top: 1px solid $light-grey-border-dark-background;
		border-right: 1px solid $light-grey-border-dark-background;
		border-left: 1px solid $light-grey-border-dark-background;
		background-color: $main-black;
		color: #fff;
		text-align: left;
		font-size: 18px;

		&:visited {
			color: #fff;
		}
	}

	* {
		border-right: none !important;
		border-left: none !important;

		.slideshow__button{
			&:first-child {
				border-right: 1px solid rgba(31,31,31,.1) !important;
			}
		}
	}
}