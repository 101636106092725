.slideshow {
	overflow: hidden;
	ul {
		position: relative;
		width: 100%;
		height: 100%;
	}

	li {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		transition: none;
		transform: translate3d(0, -100%, 0);

		&.active {
			transform: translate3d(0, 0, 0);

			&::after {
				content: '';
				display: block;
				position: absolute;
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				background: #000;
				opacity: 0;
				pointer-events: none;
				transition: opacity .3s linear .1s;
			}

			&[class*="isTransitionning"] {
				transition: transform 1400ms cubic-bezier(0.475, 0.045, 0.015, 1.010) .1s;

				&::after {
					opacity: 0.8;
				}
			}

			&.isTransitionning-previous {
				transform: translate3d(100%, 0, 0);
			}

			&.isTransitionning-next {
				transform: translate3d(-100%, 0, 0);
			}
	
		}

		&.isSetting-previous {
			transform: translate3d(-100%, 0, 0);
		}

		&.isSetting-next {
			transform: translate3d(100%, 0, 0);
		}

		&.isTransitionning {
			transition: transform 800ms cubic-bezier(0.475, 0.045, 0.015, 1.010) .1s;
			transform: translate3d(0, 0, 0);
		}
	}

	[class*="calc"] {
		position: absolute;
		height: 100%;
		width: 120%;
		top: 0;
		background: #fff;
		z-index: 100;
		transition: none;
		
		&.isTransitionning-calc {
			transition: transform 900ms cubic-bezier(0.370, -0.005, 0.010, 0.910) .1s;

		}
	}

	&__calc-previous {
		transform: translate3d(-100%, 0, 0);
		&.isTransitionning-calc {
			transform: translate3d(84%, 0, 0);
		}
	}

	&__calc-next {
		transform: translate3d(100%, 0, 0);
		&.isTransitionning-calc {
			transform: translate3d(-100%, 0, 0);
		}
	}
}

@media (max-width: 960px) {
	.slideshow {
		ul {
			position: absolute;
		}
	}
}